@import '../../../../scss/theme-bootstrap';

.header-offers-banner-formatter {
  min-height: $mobile-top-offer-height;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  width: auto;
  background-color: $color-white;
  font-size: 10px;
  @include breakpoint($large-up) {
    padding: 0 40px;
    min-height: $top-offer-height;
    padding-#{$ldirection}: 30px;
  }
  &__carousel {
    z-index: 11;
    margin-bottom: 0;
    width: 100%;
  }
  .slick-arrow {
    &::before {
      width: 14px;
      height: 14px;
    }
  }
}
